import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import FaceIcon from "@mui/icons-material/Face";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useDispatch } from "react-redux";
import { logoutAction } from "../redux/slices/users/usersSlice";
import NavBarItem from "../components/navbar-Item.jsx";
import { useAuth } from '../contexts/AuthContext';
import theme from "../ui/theme/theme.js";
import { PATHS } from "../constants/routes.ts";

const drawerWidth = 240;

function DrawerAppBar(props) {
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoggedIn, logout } = useAuth(); // useAuth hook for auth state
    const [logoutError, setLogoutError] = useState('');
    const navItems = [
        // { tabName: "Register", icon: <HowToRegIcon />, url: PATHS.REGISTER },
        {
            url: PATHS.ABOUT_US,
            icon: <InfoIcon />,
            tabName: "About Us",
        },
        // ... add other nav items here
    ];

    const logoutHandler = async () => {
        try {
            await dispatch(logoutAction()).unwrap();
            logout(); // Update AuthContext
            navigate('/'); // Navigate to home or login page
            setLogoutError(''); // Clear any existing error message
        } catch (error) {
            console.error("Logout failed:", error);
            // Handle logout failure (show error message to user)
            setLogoutError('Logout failed. Please try again.'); // Set the error message
        }
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ my: 2 }}>
              HomeCare
          </Typography>
          <Divider />
          <List>
              {navItems.map((item) => (
                  <ListItem key={item.url} disablePadding>
                      <ListItemButton sx={{ textAlign: "center" }}>
                          <NavBarItem
                              url={item.url}
                              icon={item.icon}
                              tabName={item.tabName}
                          />
                      </ListItemButton>
                  </ListItem>
              ))}
              {isLoggedIn && (
                  <ListItem disablePadding>
                      <ListItemButton sx={{ textAlign: "center" }}>
                          <NavBarItem
                              tabName="My Profile"
                              icon={<AccountCircleIcon />}
                              url={PATHS.CUSTOMERS_PROFILE}
                          />
                      </ListItemButton>
                  </ListItem>
              )}
              {!isLoggedIn ? (
                  <ListItem disablePadding>
                      <ListItemButton sx={{ textAlign: "center" }}>
                          <NavBarItem
                              tabName="Login"
                              icon={<FaceIcon />}
                              url={PATHS.LOGIN}
                          />
                      </ListItemButton>
                  </ListItem>
              ) : (
                  <ListItem disablePadding>
                      <ListItemButton sx={{ textAlign: "center" }}>
                          <Button
                              onClick={logoutHandler}
                              variant="contained"
                              sx={{
                                  background: theme.palette.secondary.main,
                                  width: "100%", // Set width to 100% to fill the drawer width
                                  justifyContent: "flex-start" // Align the button text to the left
                              }}
                          >
                              <LogoutIcon />
                              Log Out 
                          </Button>
                      </ListItemButton>
                  </ListItem>
              )}
          </List>
      </Box>
    );
    

  const container = props.window !== undefined ? () => props.window().document.body : undefined;

  
  

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ backgroundColor: "#f7faff", p: "11px 25px " }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <Box
              component="img"
              src="/images/homecare-logo.png"
              alt="Home Care Logo"
              sx={{ height: "85px", width: "auto", mr: "80px" }}
            />
          </Link>

          <Stack
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "row",
              width: "100%",
            }}
          >
            {navItems.map((item) => (
              <NavBarItem
                key={item.url}
                url={item.url}
                icon={item.icon}
                tabName={item.tabName}
              />
            ))}
            {!isLoggedIn ? (
              <NavBarItem
                tabName="Login"
                icon={<FaceIcon />}
                url={PATHS.LOGIN}
              />
            ) : (
              <>
                <NavBarItem
                  url={PATHS.CUSTOMERS_PROFILE}
                  icon={<AccountCircleIcon />}
                  tabName="My Profile"
                />

                <Button
                  onClick={logoutHandler}
                  variant="contained"
                  sx={{
                    background: theme.palette.secondary.main,
                  }}
                >
                  <LogoutIcon />
                  Log Out
                </Button>
              </>
            )}
          </Stack>
          <a
            href="https://api.whatsapp.com/send?phone=%2B97148103800"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: "auto" }}
          >
            <Typography
              component="span"
              sx={{
                width: " 40px",
                height: " 40px",
                fontSize: " 23px",
                background: " #60d668",
                display: " flex",
                justifyContent: " center",
                alignItems: " center",
                borderRadius: " 100%",

                ":hover": {
                  transform: "scale(1.2)",
                  transition: "ease-in-out .2s",
                },
              }}
            >
              <WhatsAppIcon />
            </Typography>
          </a>
        </Toolbar>
      </AppBar>
      {logoutError && <p style={{ color: 'red' }}>{logoutError}</p>}
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{keepMounted: true,  }}// Better open performance on mobile.
          sx={{ display: { xs: "block", sm: "none" }, "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth } }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
