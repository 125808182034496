import React, { useState , useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, TextField, InputAdornment, Typography, Box, Button } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import { DirectHireVisaRequestAction } from "../redux/slices/DH_visa/requestingDHvisa";
import LoadingComponent from "../hooks/LoadingComp/LoadingComponent";
// import Swal from 'sweetalert2';

const GetVisaForm = () => {
  const dispatch = useDispatch();
  const { loading, successMessage, errorMessage } = useSelector((state) => state?.visa);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    recaptchaValue: "",
  });
  
  // useEffect(() => {
  //   if (successMessage) {
  //     console.log(successMessage);
  //     Swal.fire({
  //       title: 'Success!',
  //       text: successMessage,
  //       icon: 'success',
  //       confirmButtonText: 'OK'
  //     });
  //   }
  //   if (errorMessage) {
  //     Swal.fire({
  //       title: 'Error!',
  //       text: errorMessage,
  //       icon: 'error',
  //       confirmButtonText: 'OK'
  //     });
  //   }
  // }, [successMessage, errorMessage]);


  const onRecaptchaChange = (value) => {
    setFormData({ ...formData, recaptchaValue: value });
  };
  
  
  const cleanPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\s+/g, '').trim();
  };

  const validatePhoneNumber = (phoneNumber) => {
    const cleanedPhone = cleanPhoneNumber(phoneNumber);
    return /^05\d{8}$/.test(cleanedPhone);
  };

  const { fullName, phoneNumber, email, recaptchaValue } = formData;


  const [phoneError, setPhoneError] = useState('');

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'phoneNumber') {
      if (!value.startsWith("05")) {
        setPhoneError("Phone number must start with '05'");
      } else if (!validatePhoneNumber(value)) {
        setPhoneError("Invalid phone number format");
      } else {
        setPhoneError(""); // Clear any existing error messages
      }
    }
  };


  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(DirectHireVisaRequestAction({ fullName, phoneNumber, email, recaptchaValue })).unwrap();
      // Reset form
      setFormData({ fullName: '', phoneNumber: '', email: '', recaptchaValue: '' });
      const successMessage = response?.message || 'Your visa request has been submitted successfully!';
      // Redirect to the success page with a dynamic message
      navigate('/visa-thank-you', { state: { message: successMessage , returnPath: '/get-visa'} });
    } catch (error) {
      console.error('Failed to submit the visa request:', error);
      // Handle error scenario, potentially navigate with an error message if needed
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={onSubmitHandler}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        m: 1,
        p: { xs: 1, md: 3 },
        width: '100%',
        position: 'relative', // Ensure the form has its own stacking context
        zIndex: 2, // Higher z-index ensures it's above other elements
      }}
    >
      <Typography
        variant="h4"
        component="h4"
        sx={{
          fontSize: { xs: "16px", md: "20px" },
          width: '100%',
          mt: { xs: 2, md: 4 },
          mb: { xs: 2, md: 4 },
          textAlign: "center",
          color: "#939496",
          fontWeight: "bold",
        }}
      >
        Please enter your details
      </Typography>
      <Stack
        spacing={3}
        sx={{
          width: '100%',
          maxWidth: { sm: '90%', md: '500px' },
          ".MuiInputBase-root": {
            borderRadius: "30px",
          },
        }}
      >
        <TextField
          id="sign-in-fullname"
          fullWidth
          type="text"
          label="Full Name"
          name="fullName"
          onChange={onChangeHandler}
          value={fullName}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="sign-in-phone"
          fullWidth
          type="text"
          label="Phone Number"
          name="phoneNumber"
          onChange={onChangeHandler}
          value={phoneNumber}
          error={!!phoneError}
          helperText={phoneError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocalPhoneIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="sign-in-email"
          fullWidth
          type="email"
          label="Email"
          name="email"
          onChange={onChangeHandler}
          value={email}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <Stack alignItems="center">
          <ReCAPTCHA
            sitekey="6Le2EWwpAAAAAL7p6bYBZf57evb3cl6LSHXjIkcD"
            onChange={onRecaptchaChange}
          />
        </Stack>
        {loading ? (
          <LoadingComponent />
        ) : (
          <Button
            id="login-button"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
              borderRadius: "30px",
              mt: 2,
              zIndex: 2, // Ensure button is not covered by any other element
              position: 'relative',
            }}
            disabled={!fullName || !phoneNumber || !email || !!phoneError}
          >
            Send
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default GetVisaForm;
