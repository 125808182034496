import React, { useEffect, useState } from "react";
import ComputerViewForMaidsList from "./computerView/ComputerViewForMaidsList";
import { useNavigate } from "react-router";
import { useStore } from '../utils/store'; // Update this path
import { useAuth } from '../contexts/AuthContext';

const MaidsList = ({ data }) => {
  const { isLoggedIn } = useAuth(); // Use the isLoggedIn state from useAuth
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const selectMaid = useStore(state => state.selectMaid);
  const selectedMaid = useStore(state => state.selectedMaid);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobileView = windowWidth <= 700;

  const handleHireClick = () => {
      if (selectedMaid) {
        const amountsByNationality = {
          Philippines: 3700,
          Ethiopia: 2900,
          Indonesia: 3500,
          Myanmar: 3500,
          default: 3500,
        };
        
        const amount = amountsByNationality[selectedMaid.nationality] || amountsByNationality.default;

        // Update the selectedMaid object to include the amount
        const updatedSelectedMaid = {...selectedMaid, amount};
        selectMaid(updatedSelectedMaid);

        navigate('/maid-details');
      } else {
        alert("Please select a maid first.");
      }
    };

  return (
    <>
      <ComputerViewForMaidsList
        data={data}
        isLoading={isLoading}
        handleHireClick={handleHireClick}
      />

      {/* )} */}
    </>
  );
};

export default MaidsList;
