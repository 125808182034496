import React , { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FooterSocialMediaLinks from "../../components/footer-Social-media-links";
import HomeCareCopyRight from "../../components/home-care-copy-right";
import { Helmet } from 'react-helmet-async'; // Import Helmet
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import GetVisaForm from "../../components/get-visa-form";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const GetVisa = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  return (
    <>
      <Helmet>
        <title id="title">Homecare  Apply or Renew your Maid's  Visa Online in 5 min</title>
        <meta name="description" 
          content="Sign &amp; Pay Online. We’ll handle the entire visa process and issue your maid’s Residency Visa. Avoid visits to medical centers &amp; typists."
        />
        <link rel='canonical' href='/get-visa' />
      </Helmet>
      <Grid container spacing={0} sx={{overflowY: 'auto', minHeight: 'calc(100vh - 56px)' }}>
        <Grid item xs={12} md={5} sx={{
          background: "repeating-linear-gradient(0deg, #5a5a5a, #7d7e7e, #8a8a8a, #939597, #a2a2a2, #a5a5a5, #b2b2b2, #ababab)",
          px: { xs: 2, md: 4 },
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Stack direction="column" alignItems="center" justifyContent="center" sx={{
            overflowY: 'auto',
            flexGrow: 1,
          }}>
            <Box
              component="img"
              src="/images/logo-white.png"
              alt="Home Care Logo"
              sx={{
                width: { xs: '130px', sm: '180px', md: '210px' },
                height: 'auto',
                my: 2,
              }}
            />
            <Box sx={{ textAlign: 'center', color: 'white', width: '100%' }}>
              <Typography variant="h2" component="h2" sx={{
                fontWeight: 'bold',
                fontSize: { xs: '24px', sm: '36px', md: '48px' },
                mb: { xs: 1, sm: 2 },
                textTransform: 'uppercase',
              }}>
                Get a visa
              </Typography>
              <Typography sx={{
                fontSize: { xs: '12px', sm: '14px', md: '16px' },
                mb: { xs: 2, sm: 3 },
              }}>
                Want us to sponsor your maid?<br />
                Get a 2-year visa from the comfort of your home, in less than 2 weeks!<br />
                For 8,200 AED + 260 AED/month
              </Typography>
              <Typography component="div" sx={{
                fontSize: { xs: '14px', sm: '15px', md: '16px' },
                mb: { xs: 1, sm: 1 },
              }}>
                <strong>Includes:</strong>
              </Typography>
              <Box component="ul" sx={{ 
                listStyleType: 'disc', 
                pl: { xs: 2, sm: 3 }, 
                textAlign: 'left', 
                margin: '0 auto', 
                display: 'inline-block', 
                width: 'fit-content',
                maxWidth: '100%',
              }}>
                {['2-year visa.', 'EID, Medical Insurance, and ATM Card.', 'PRO to assist with bans and failed medicals.', 'Dedicated Customer-Service Agent.', 'WPS payroll.', 'Zero centre visits, all done online.', 'Zero Deposit.'].map((item, index) => (
                  <Typography key={index} component="li" sx={{ pb: 0.5, fontSize: { xs: '12px', sm: '14px', md: '16px' } }}>
                    {item}
                  </Typography>
                ))}
              </Box>
              <Typography sx={{
                fontSize: { xs: '12px', sm: '14px', md: '16px' },
                fontStyle: 'italic',
                mt: { xs: 1, sm: 3 },
                display:"block"
              }}>
                This payment plan doesn't suit you? Contact us to know more about our available payment plans!
                <FooterSocialMediaLinks />
                <HomeCareCopyRight />
              </Typography>
            </Box>
           
          </Stack>
        </Grid>
        <Grid item xs={12} md={7} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: { xs: 2, md: 4 }, // Ensure padding is responsive
        }}>
          <Box    
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          sx={{
            width: '100%',
            maxWidth: { sm: '500px' }, // Limit the width of the form on larger screens
          }}>
            <GetVisaForm />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GetVisa;
